
import FilterMenu from "~/components/rent-levels/FilterMenu.vue";
import Notification from "~/components/rent-levels/Notification.vue";
import LoaderCopy from "~/components/Loader/LoaderCopy.vue";

import { COLORS as chartColors } from "~/components/Chart/configuration/colors";

import TableRowAdd from "~/components/rent-levels/TableRowAdd.vue";
import TableRowBody from "~/components/rent-levels/TableRowBody.vue";
import TableRowHeader from "~/components/rent-levels/TableRowHeader.vue";
import TableRowBodyDynamic from "~/components/rent-levels/TableRowBodyDynamic.vue";
import TableRowHeaderDynamic from "~/components/rent-levels/TableRowHeaderDynamic.vue";

import ModalExport from "~/components/rent-levels/ModalExport.vue";
import ModalBulkEdit from "~/components/rent-levels/ModalBulkEdit.vue";
import ModalRecalculate from "~/components/rent-levels/ModalRecalculate.vue";
import ModalExportLoading from "~/components/rent-levels/ModalExportLoading.vue";
import ModalRequestContracts from "~/components/rent-levels/ModalRequestContracts.vue";

import WidgetA from "~/components/rent-levels/WidgetA.vue";
import WidgetB from "~/components/rent-levels/WidgetB.vue";
import WidgetC from "~/components/rent-levels/WidgetC.vue";
import WidgetD from "~/components/rent-levels/WidgetD.vue";
import WidgetE from "~/components/rent-levels/WidgetE.vue";
import WidgetF from "~/components/rent-levels/WidgetF.vue";
import WidgetG from "~/components/rent-levels/WidgetG.vue";
import WidgetH from "~/components/rent-levels/WidgetH.vue";
import WidgetI from "~/components/rent-levels/WidgetI.vue";
import WidgetJ from "~/components/rent-levels/WidgetJ.vue";

import Me from "~/graphql/Authentication/Me.gql";
import VacancyRates from "~/graphql/Property/VacancyRates.gql";
import FauxProperty from "~/graphql/Property/FauxProperty.gql";
import UpdateFauxUnit from "~/graphql/Property/UpdateFauxUnit.gql";
import DeleteFauxUnit from "~/graphql/Property/DeleteFauxUnit.gql";
import RequestContracts from "~/graphql/Property/RequestContracts.gql";
import SetFauxUnitSector from "~/graphql/Property/SetFauxUnitSector.gql";
import DeleteFauxProperty from "~/graphql/Property/DeleteFauxProperty.gql";
import UpdateFauxBuilding from "~/graphql/Property/UpdateFauxBuilding.gql";
import CreateFauxProperty from "~/graphql/Property/CreateFauxProperty.gql";
import AddFauxUnitToFauxBuilding from "~/graphql/Property/AddFauxUnitToFauxBuilding.gql";
import UpdateFauxPropertyUnitSectors from "~/graphql/Property/UpdateFauxPropertyUnitSectors.gql";
import SetFauxPropertyRentCalculation from "~/graphql/Property/SetFauxPropertyRentCalculation.gql";
import PropertySimulationsRentalLevels from "~/graphql/Property/PropertySimulationsRentalLevels.gql";
import PropertyFauxPropertyRentalLevels from "~/graphql/Property/PropertyFauxPropertyRentalLevels.gql";

import { sortByAddress } from "~/helpers/sort-helpers.js";
import {
  colorBySectorId,
  getTypeFromSector,
  colorNameBySectorId,
  getReferenceNumberOfUnits,
  getReferenceTotalArea,
  getReferenceDistanceToUnits,
  getReferenceConfidence,
  getReferenceMovingInYear,
  getReferenceConstructionYear,
  getReferenceResidentialConstructions,
  getReferenceSizeByType,
} from "~/helpers/rent-level-helpers.js";
import { getAddressAsLongString } from "~/helpers/address-helpers.js";
import { getBuildings, getUnits } from "~/helpers/property-helpers.js";
import { getPropertyAddress, getPropertyCentroid } from "~/helpers/property-helpers.js";
import { writeSheetsToXSLXAndSave, createRentalLevelsSheet } from "~/helpers/download-helpers.js";

import PropertyRentalLevels from "~/graphql/Property/PropertyRentalLevels.gql";
import PropertyFauxProperties from "~/graphql/Property/PropertyFauxProperties.gql";

import { PERMISSIONS } from "~/composables/usePermissions";

export default {
  title() {
    return this.$t("MENU_RENTAL");
  },

  components: {
    FilterMenu,
    LoaderCopy,
    Notification,

    TableRowAdd,
    TableRowBody,
    TableRowHeader,
    TableRowBodyDynamic,
    TableRowHeaderDynamic,

    WidgetA,
    WidgetB,
    WidgetC,
    WidgetD,
    WidgetE,
    WidgetF,
    WidgetG,
    WidgetH,
    WidgetI,
    WidgetJ,

    ModalExport,
    ModalBulkEdit,
    ModalRecalculate,
    ModalExportLoading,
    ModalRequestContracts,
  },

  apollo: {
    me: {
      query: Me,
    },

    property: {
      query: PropertyRentalLevels,
      update(data) {
        return data.propertyByBFENumber;
      },
      error(error) {
        console.error("getPropertyRentalLevelsQuery failed:", error);
      },
      variables() {
        return {
          bfeNumber: parseInt(this.$route.params.id, 10),
          ...(this.searchFilters && { search: { sectorFilters: this.searchFilters } }),
        };
      },
      skip() {
        return this.selectedProperty != null && this.selectedProperty.id !== "propertyRentalPrice";
      },
    },

    fauxProperties: {
      query: PropertyFauxProperties,
      update(data) {
        return data.fauxPropertiesByBfeNumber || [];
      },
      error(error) {
        console.error("getPropertyRentalLevelsQuery failed:", error);
      },
      variables() {
        return {
          bfeNumber: parseInt(this.$route.params.id, 10),
        };
      },
    },

    vacancyRates: {
      query: VacancyRates,
      update(data) {
        return data.vacancyRates;
      },
      error(error) {
        console.error("vacancyRates failed:", error);
      },
      variables() {
        return {
          postalCode: this.propertyPostalCode,
        };
      },
      skip() {
        return this.property == null;
      },
    },
  },

  data() {
    return {
      mainProperty: null,
      sectorFilters: null,
      customSortOrder: [],
      sortDirection: "asc",
      exportLoading: false,
      deleteLoading: false,
      selectedProperty: null,
      hightlightedUnit: null,
      expandUnitTable: false,
      totalYearlyRentCache: [],
      bulkEditModalActive: false,
      downloadModalActive: false,
      fauxPropertyDataEdited: false,
      fauxPropertyRentalLevels: null,
      selectedSort: this.sortByAddress,
      createFauxPropertyLoading: false,
      createFauxPropertyWithUnits: true,
      downloadConditionsConfirmed: false,
      fauxPropertyRentalLevelsLoading: false,
      requestRentContractsModalActive: false,
      rentalRequestConditionsConfirmed: false,

      // NOTE: cllpse: keep the colors aligned between ~/helpers/rent-level-helpers.js:colorBySectorId and <ChartDonut />
      sectors: ["RESIDENTIAL_FREE_RENT", "SHOP", "OFFICE", "INDUSTRY", "OTHER_BUSINESS", "RESIDENTIAL_SMALL", "RESIDENTIAL_RENTAL", "RESIDENTIAL_COST"],
    };
  },

  computed: {
    loading: function () {
      return this.property == null || this.$apollo.queries.property.loading || this.$apollo.queries.vacancyRates.loading;
    },

    simulationsLoading: function () {
      return this.$apollo.queries.fauxProperties.loading;
    },

    simulationsRentalLevelsLoading: function () {
      return this.fauxPropertyRentalLevelsLoading;
    },

    data: function () {
      let out = [];

      if (this.property.plots) {
        getBuildings(this.property).forEach((b) => {
          b.units?.forEach((u) => {
            if (u.totalArea >= 0) {
              out.push(this.formatUnitAndBuilding(u, b));
            }
          });
        });
      } else if (this.property.condo) {
        const units = getUnits(this.property);
        if (units.length > 0) {
          out.push(this.formatUnitAndBuilding(units[0], units[0].building));
        }
      }

      if (this.selectedSort) {
        out.sort(this.selectedSort);
      }

      if (this.sortDirection === "desc") {
        out.reverse();
      }

      return out;
    },

    propertyPostalCode: function () {
      return getPropertyAddress(this.property)?.postalCode;
    },

    dropdownItems: function () {
      return [this.mainProperty, ...(this.fauxProperties || [])];
    },

    units: function () {
      let u;
      if (!this.isSimulationProperty) {
        u = this.data;
      } else {
        u = this.selectedSimulationUnits.map((u) => this.getRentalEstimatedUnit(u, this.fauxPropertyRentalLevels)).filter((u) => u != null);
      }

      return u;
    },

    sectorsInProperty: function () {
      const out = [];

      this.units.forEach((u) => {
        if (u.sectorEnum != null) {
          out.push(u.sectorEnum);
        } else if (u.rentalEstimate != null) {
          out.push(u.rentalEstimate.sector);
        }
      });

      return [...new Set(out)];
    },

    colorsNotInUse: function () {
      return chartColors.filter((e) => !this.sectorsInProperty.map((s) => colorNameBySectorId(s)).includes(e)).map((c) => `--color-${c}-500`);
    },

    sectorsWithNoResults: function () {
      return this.units
        .filter((u) => u.rentalEstimate && u.rentalEstimate.avgSqmRent == null)
        .map((u) => u.rentalEstimate.sector)
        .filter((v, i, a) => a.indexOf(v) === i);
    },

    noResultsErrorText() {
      if (this.sectorsWithNoResults.length === 0) {
        return "";
      }

      const sectorsString = this.sectorsWithNoResults.map((s) => getTypeFromSector(s, this.$i18n)).join(", ");

      return this.$t("RENT_LEVELS_NEW_FILTERMENU_FILTER_TOO_SPECIFIC", { sectors: sectorsString });
    },

    referenceData: function () {
      const out = {};

      this.units.forEach((u) => {
        if (u.rentalEstimate && out[u.rentalEstimate.sector] == null && u.rentalEstimate.referenceData != null) {
          out[u.rentalEstimate.sector] = u.rentalEstimate.referenceData;
        }
      });

      return out;
    },

    referenceNumberOfUnits: function () {
      return getReferenceNumberOfUnits(this.referenceData);
    },

    referenceTotalArea: function () {
      return getReferenceTotalArea(this.referenceData);
    },

    referenceDistanceToUnits: function () {
      return getReferenceDistanceToUnits(this.referenceData);
    },

    referenceConfidence: function () {
      return getReferenceConfidence(this.referenceData);
    },

    referenceMovingInYear: function () {
      return getReferenceMovingInYear(this.referenceData);
    },

    referenceConstructionYear: function () {
      return getReferenceConstructionYear(this.referenceData);
    },

    referenceResidentialConstructions: function () {
      return getReferenceResidentialConstructions(this.referenceData);
    },

    referenceSizeByType() {
      return getReferenceSizeByType(this.referenceData);
    },

    unitsForTable: function () {
      if (!this.expandUnitTable) return this.units.slice(0, 10);

      return this.units;
    },

    remainingUnits: function () {
      return this.units.length - this.unitsForTable.length;
    },

    showExpandButton: function () {
      return this.units.length > 10;
    },

    totalArea: function () {
      return this.units.reduce((a, b) => a + b.totalArea, 0);
    },

    totalYearlyAvgRent: function () {
      return this.units.filter((u) => u?.rentalEstimate).reduce((a, b) => a + this.getUnitTotalRent(b), 0);
    },

    totalYearlyBottomRent: function () {
      return this.units.filter((u) => u?.rentalEstimate).reduce((a, b) => a + this.getUnitRentalPriceMin(b) * b.totalArea, 0);
    },

    totalYearlyTopRent: function () {
      return this.units.filter((u) => u?.rentalEstimate).reduce((a, b) => a + this.getUnitRentalPriceMax(b) * b.totalArea, 0);
    },

    totalYearlySquareMeterAvgRent: function () {
      return this.totalYearlyAvgRent / this.totalArea;
    },

    totalYearlySquareMeterTopRent: function () {
      return this.totalYearlyTopRent / this.totalArea;
    },

    totalYearlySquareMeterBottomRent: function () {
      return this.totalYearlyBottomRent / this.totalArea;
    },

    rentTotals: function () {
      return this.sectorsInProperty.reduce((a, b) => {
        a[b] = {
          totalYearlyTopRent: this.getTotalYearlyTopRentForSector(b),
          totalYearlyAvgRent: this.getTotalYearlyAvgRentForSector(b),
          totalYearlyBottomRent: this.getTotalYearlyBottomRentForSector(b),
          totalYearlyTopSquareMeterRent: this.getTotalYearlySquareMeterTopRentForSector(b),
          totalYearlyAvgSquareMeterRent: this.getTotalYearlySquareMeterAvgRentForSector(b),
          totalYearlyBottomSquareMeterRent: this.getTotalYearlySquareMeterBottomRentForSector(b),
          vacancyRate: this.getVacancyRateForSector(b),
          vacancyDescription: this.getVacancyDescriptionForSector(b),
        };
        return a;
      }, {});
    },

    showNotificationMissingData: function () {
      if (this.selectedProperty.id == "propertyRentalPrice") {
        return this.units.some((u) => u.rentalEstimate == null);
      } else {
        return this.units.map((u) => this.getRentalEstimatedUnit(u, this.fauxPropertyRentalLevels)).some((u) => u?.rentalEstimate == null);
      }
    },

    showOtherBusinessBanner: function () {
      if (this.selectedProperty.id == "propertyRentalPrice") {
        return this.units.some((u) => u.rentalEstimate?.sector == "OTHER_BUSINESS");
      } else {
        return this.units.map((u) => this.getRentalEstimatedUnit(u, this.fauxPropertyRentalLevels)).some((u) => u?.rentalEstimate?.sector == "OTHER_BUSINESS");
      }
    },

    showRefetchNotification: function () {
      return this.fauxPropertyDataEdited && this.selectedProperty.id !== "propertyRentalPrice";
    },

    selectedSimulation: function () {
      return this.fauxProperties.find((s) => s.id == this.selectedProperty.id);
    },

    selectedSimulationUnits: function () {
      if (this.selectedProperty == null || this.deleteLoading) return [];

      let out = [...(this.fauxProperties || [])]
        ?.find((s) => s.id == this.selectedProperty.id)
        ?.buildings?.flatMap((b) => b.units.map((u) => this.formatUnitAndBuilding(u, b)))
        ?.sort(this.selectedSort);

      if (out && this.selectedSort != "sortByLastOrder" && this.sortDirection === "desc") {
        out.reverse();
      }

      return out;
    },

    showRentalRequestButton: function () {
      const RENTAL_REQUEST_READ = PERMISSIONS.RENT_REQUEST_READ_ORG;

      return this.me.permissions.some((p) => p.indexOf(RENTAL_REQUEST_READ) != -1);
    },

    isSimulationProperty: function () {
      return this.selectedProperty?.id !== "propertyRentalPrice";
    },

    searchFilters: function () {
      const propertyCentroid = getPropertyCentroid(this.property);

      return this.sectorFilters?.map((s) => {
        if (s.circle != null) {
          s.circle.centre = {
            lat: propertyCentroid.lat,
            lon: propertyCentroid.lng,
          };
        }

        return s;
      });
    },

    sectorsWithUsageType() {
      return this.sectors.filter((s) => {
        return s === "RESIDENTIAL_FREE_RENT" || s === "RESIDENTIAL_SMALL" || s === "RESIDENTIAL_RENTAL" || s === "RESIDENTIAL_COST";
      });
    },
  },

  watch: {
    property(next, prev) {
      if (prev != null) return;

      const propertyForDropdown = { id: "propertyRentalPrice", name: " " };
      this.mainProperty = propertyForDropdown;
      this.selectedProperty = propertyForDropdown;
    },

    selectedSort() {
      this.customSortOrder = this.selectedSimulationUnits?.map((u) => u.id);
    },

    selectedSimulationUnits(value) {
      if (value && (this.customSortOrder == null || this.customSortOrder.length == 0)) {
        this.customSortOrder = value.map((u) => u.id);
      }
    },

    hightlightedUnit(current, prev) {
      this.$nextTick(() => {
        const highLightedNode = document.querySelectorAll(`#row-${prev}`)[0];
        if (highLightedNode) {
          highLightedNode.classList.remove("highlight");
        }

        const nodeToHighLight = document.querySelectorAll(`#row-${current}`)[0];
        if (nodeToHighLight) {
          nodeToHighLight.classList.add("highlight");
        }
      });
    },

    selectedProperty(next) {
      if (next.id == "propertyRentalPrice") {
        this.$amplitude.track({ event_type: "Navigate to 1st Tab" });
        return;
      }

      const simulation = this.fauxProperties.find((p) => p.id === next.id);
      this.simulationUnits = simulation?.units;

      this.fetchSimulationRentalLevels(next.id);
    },

    sectorFilters() {
      //We have to refertch simulation rental levels manually since they are not in the Apollo scope
      if (this.selectedProperty.id !== "propertyRentalPrice") {
        this.fetchSimulationRentalLevels(this.selectedProperty.id);
      }
    },
  },

  methods: {
    ejendomDanmarkClick() {
      this.$amplitude.track({ event_type: "EjendomDanmark" });

      window.open("https://ejd.dk/statistik/lejetjek/lejetjekplus/", "_blank").focus();
    },

    filterMenuChange(filters) {
      this.sectorFilters = filters;
    },

    formatUnitAndBuilding(unit, building) {
      const clone = structuredClone(unit);

      clone.buildingNumber = building.buildingNumber;
      clone.constructionYear = building.constructionYear;
      clone.key = clone.id + building.constructionYear; // This will force reactivity of the unit when building contruction year is change

      return clone;
    },

    getUnitAddress(unit) {
      return unit?.address;
    },

    getUnitSectorId(unit) {
      return unit?.sectorEnum || unit?.rentalEstimate?.sector;
    },

    getUnitType(unit) {
      return unit.rentalEstimate ? getTypeFromSector(unit.rentalEstimate.sector, this.$i18n) : undefined;
    },

    getUnitArea(unit) {
      return unit?.totalArea;
    },

    getUnitBuildingNumber(unit) {
      return parseInt(unit?.buildingNumber, 10);
    },

    getUnitBuildingId(unit) {
      return unit?.fauxBuildingId;
    },

    getUnitCommuneCode(unit) {
      return unit?.communeCode;
    },

    getUnitUsageCode(unit) {
      return unit?.usageCode;
    },

    getUnitConstructionDate(unit) {
      return unit?.constructionYear?.toString();
    },

    getTotalAreaForSector: function (sector) {
      return this.units.filter((u) => u.rentalEstimate?.sector == sector && u.rentalEstimate && this.hasRentalEstimate(u)).reduce((a, b) => a + b.totalArea, 0);
    },

    getTotalYearlyTopRentForSector: function (sector) {
      return this.units.filter((u) => u.rentalEstimate?.sector == sector).reduce((a, b) => a + this.getUnitRentalPriceMax(b) * b.totalArea, 0);
    },

    getTotalYearlyAvgRentForSector: function (sector) {
      return this.units.filter((u) => u.rentalEstimate?.sector == sector).reduce((a, b) => a + this.getUnitRentalPriceAvg(b) * b.totalArea, 0);
    },

    getTotalYearlyBottomRentForSector: function (sector) {
      return this.units.filter((u) => u.rentalEstimate?.sector == sector).reduce((a, b) => a + this.getUnitRentalPriceMin(b) * b.totalArea, 0);
    },

    getTotalYearlySquareMeterTopRentForSector: function (sector) {
      return this.getTotalYearlyTopRentForSector(sector) / this.getTotalAreaForSector(sector);
    },

    getTotalYearlySquareMeterAvgRentForSector: function (sector) {
      return this.getTotalYearlyAvgRentForSector(sector) / this.getTotalAreaForSector(sector);
    },

    getTotalYearlySquareMeterBottomRentForSector: function (sector) {
      return this.getTotalYearlyBottomRentForSector(sector) / this.getTotalAreaForSector(sector);
    },

    getVacancyRateForSector: function (sector) {
      if (this.vacancyRates == null) {
        return null;
      }

      return this.getVacancyForSector(sector)?.rate;
    },

    getVacancyDescriptionForSector: function (sector) {
      if (this.vacancyRates == null) {
        return null;
      }

      return this.getVacancyForSector(sector)?.description;
    },

    getVacancyForSector(sector) {
      let vacancyType;
      switch (sector) {
        case "SHOP":
          vacancyType = "shop";
          break;
        case "INDUSTRY":
          vacancyType = "industry";
          break;
        case "OFFICE":
          vacancyType = "office";
          break;
        case "OTHER_BUSINESS":
          vacancyType = "otherBusiness";
          break;
        case "RESIDENTIAL_COST":
        case "RESIDENTIAL_SMALL":
        case "RESIDENTIAL_FREE_RENT":
        case "RESIDENTIAL_RENTAL":
          vacancyType = "residential";
          break;
      }

      return this.vacancyRates.find((v) => v.type === vacancyType);
    },

    hasRentalEstimate(unit) {
      return this.getUnitRentalPriceMax(unit) != null && this.getUnitRentalPriceMin(unit) != null && this.getUnitRentalPriceAvg(unit) != null;
    },

    getUnitTotalRent(unit) {
      if (unit?.rentalEstimate == null || unit?.totalArea == null) {
        return undefined;
      }

      return unit.rentalEstimate.avgSqmRent * unit.totalArea;
    },

    getUnitRentalPriceMin(unit) {
      if (unit?.rentalEstimate == null) {
        return undefined;
      }

      return unit.rentalEstimate.bottomSqmRent;
    },

    getUnitRentalPriceAvg(unit) {
      if (unit?.rentalEstimate == null) {
        return undefined;
      }

      return unit.rentalEstimate.avgSqmRent;
    },

    getUnitRentalPriceMax(unit) {
      if (unit?.rentalEstimate == null) {
        return undefined;
      }

      return unit.rentalEstimate.topSqmRent;
    },

    getBuildingNumbers(simulation) {
      return [...new Set(simulation.buildings.map((b) => b.buildingNumber))].filter((b) => b != null).sort();
    },

    getRentalEstimatedUnit(unit, property) {
      const rentUnit = property?.buildings
        ?.flatMap((b) => b?.units)
        ?.filter((u) => u != null)
        ?.find((u) => u.id == unit.id);

      if (unit.sectorEnum == null) {
        unit.sectorEnum = rentUnit?.rentalEstimate?.sector;
      }
      unit.rentalEstimate = rentUnit?.rentalEstimate;

      return unit;
    },

    areSimulationsSupported() {
      return this.$isFeatureSupported("rentalDataSimulations") && this.property.plots != null;
    },

    generateSimulationName() {
      let high = 1;

      this.fauxProperties.forEach((fauxProperty) => {
        const n = parseInt((fauxProperty?.name || "").replace(/\D/g, ""), 10);

        if (n >= high) {
          high = n + 1;
        }
      });

      return high.toString();
    },

    sort(options) {
      this.sortDirection = options.direction;

      switch (options.key) {
        case "address":
          this.selectedSort = this.sortByAddress;
          break;
        case "sector":
          this.selectedSort = this.sortBySector;
          break;

        case "buildingNumber":
          this.selectedSort = this.sortByBuildingNumber;
          break;

        case "constructionYear":
          this.selectedSort = this.sortByConstructionYear;
          break;

        case "area":
          this.selectedSort = this.sortByArea;
          break;

        case "rentalLow":
          this.selectedSort = this.sortByBottomRent;
          break;

        case "rentalAverage":
          this.selectedSort = this.sortByAvgRent;
          break;

        case "rentalHigh":
          this.selectedSort = this.sortByTopRent;
          break;

        case "rentalTotal":
          this.selectedSort = this.sortByTotalRent;
          break;
      }
    },

    sortByAddress(a, b) {
      if (a.address != null) {
        return sortByAddress(a, b);
      } else if (a.name) {
        return a.name.localeCompare(b.name);
      } else {
        return -1;
      }
    },

    sortBySector(a, b) {
      if (a.rentalEstimate?.sector) {
        return a.rentalEstimate.sector.localeCompare(b.rentalEstimate.sector);
      } else if (a.sectorEnum) {
        return a.sectorEnum.localeCompare(b.sectorEnum);
      } else {
        return -1;
      }
    },

    sortByBuildingNumber(a, b) {
      return a.buildingNumber - b.buildingNumber;
    },

    sortByArea(a, b) {
      return a.totalArea - b.totalArea;
    },

    sortByBottomRent(a, b) {
      if (!a.rentalEstimate || !b.rentalEstimate) {
        a = this.getRentalEstimatedUnit(a, this.fauxPropertyRentalLevels);
        b = this.getRentalEstimatedUnit(b, this.fauxPropertyRentalLevels);
      }

      return this.getUnitRentalPriceMin(a) - this.getUnitRentalPriceMin(b);
    },

    sortByAvgRent(a, b) {
      if (!a.rentalEstimate || !b.rentalEstimate) {
        a = this.getRentalEstimatedUnit(a, this.fauxPropertyRentalLevels);
        b = this.getRentalEstimatedUnit(b, this.fauxPropertyRentalLevels);
      }

      return this.getUnitRentalPriceAvg(a) - this.getUnitRentalPriceAvg(b);
    },

    sortByTopRent(a, b) {
      if (!a.rentalEstimate || !b.rentalEstimate) {
        a = this.getRentalEstimatedUnit(a, this.fauxPropertyRentalLevels);
        b = this.getRentalEstimatedUnit(b, this.fauxPropertyRentalLevels);
      }

      return this.getUnitRentalPriceMax(a) - this.getUnitRentalPriceMax(b);
    },

    sortByTotalRent(a, b) {
      if (!a.rentalEstimate || !b.rentalEstimate) {
        a = this.getRentalEstimatedUnit(a, this.fauxPropertyRentalLevels);
        b = this.getRentalEstimatedUnit(b, this.fauxPropertyRentalLevels);
      }

      return this.getUnitTotalRent(a) - this.getUnitTotalRent(b);
    },

    sortByLastOrder(a, b) {
      if (this.customSortOrder) {
        return this.customSortOrder.indexOf(a.id) - this.customSortOrder.indexOf(b.id);
      }
    },

    recalculateRentalLevels(simulationId) {
      this.fetchSimulationRentalLevels(simulationId);
      this.$amplitude.track({
        event_type: "Recalculate",
        event_properties: {
          numberOfUnits: this.selectedSimulationUnits.length,
        },
      });
    },

    async fetchSimulationRentalLevels(simulationId) {
      this.fauxPropertyRentalLevelsLoading = true;
      this.fauxPropertyDataEdited = false;

      const result = await this.$apollo.query({
        query: PropertyFauxPropertyRentalLevels,
        fetchPolicy: "network-only",
        errorPolicy: "ignore",
        variables: {
          id: simulationId,
          ...(this.searchFilters && { search: { sectorFilters: this.searchFilters } }),
        },
      });

      this.fauxPropertyRentalLevels = result.data?.fauxProperty || [];
      this.fauxPropertyRentalLevelsLoading = false;
    },

    async createFauxProperty() {
      this.createFauxPropertyLoading = true;
      this.hightlightedUnit = null;

      let buildings;
      if (!this.property.plots.some((p) => p.buildings != null)) {
        let plotCentroid = this.property.plots.find((p) => p.geometry != null)?.geometry.centroid;

        buildings = [
          {
            latitude: plotCentroid.lat,
            longitude: plotCentroid.lng,
            buildingNumber: 1,
          },
        ];
      } else {
        buildings = this.property.plots
          .flatMap((p) => p.buildings)
          ?.filter((b) => b != null)
          ?.map((b) => {
            return {
              buildingId: b.id,
              buildingNumber: b.buildingNumber,
              constructionYear: b.constructionYear,
              latitude: b.latitude,
              longitude: b.longitude,
              units: this.createFauxPropertyWithUnits
                ? b.units?.map((u) => {
                    return {
                      unitId: u.id,
                      name: this.$options.filters.addressShort(u.address),
                      totalArea: u.totalArea,
                      usageCode: u.usageCode,
                      communeCode: u.address.municipalityCode,
                    };
                  })
                : [],
            };
          });
      }

      let fauxProperty = await this.$apollo.mutate({
        mutation: CreateFauxProperty,
        variables: {
          input: {
            bfeNumber: this.property.bfeNumber,
            name: this.generateSimulationName(),
            buildings: buildings,
          },
        },
      });

      this.$amplitude.track({
        event_type: "Create simulation",
        event_properties: {
          bfe: this.property.bfeNumber,
        },
      });

      await this.$apollo.queries.fauxProperties.refetch();

      this.createFauxPropertyLoading = false;

      const id = fauxProperty.data.createFauxProperty.id;

      if (id) {
        this.selectedProperty = this.fauxProperties.find((p) => p.id === id);
      }
    },

    async deleteFauxProperty(item) {
      if (confirm(`${this.$t("RENTAL_LABEL_CONFIRM_DELETE")} ${item.textHeader}?`)) {
        this.deleteLoading = true;
        this.selectedSort = this.sortByLastOrder;

        await this.$apollo.mutate({
          mutation: DeleteFauxProperty,
          variables: {
            input: item.id,
          },
        });

        this.$amplitude.track({
          event_type: "Delete simulation",
          event_properties: {
            simulation: item.textHeader,
          },
        });

        await this.$apollo.queries.fauxProperties.refetch();

        if (this.selectedProperty.id == item.id) {
          this.selectedProperty = this.dropdownItems[0];
        }

        this.deleteLoading = false;
      }
    },

    async duplicateFauxUnit(item) {
      this.fauxPropertyDataEdited = true;
      this.selectedSort = this.sortByLastOrder;

      this.$apollo.mutate({
        mutation: AddFauxUnitToFauxBuilding,
        variables: {
          buildingId: item.buildingId,
          input: {
            communeCode: item.communeCode,
            name: item.name,
            totalArea: item.totalArea,
            usageCode: item.usageCode,
          },
        },

        update: (store, { data: { addFauxUnitToFauxBuilding } }) => {
          const data = structuredClone(
            store.readQuery({
              query: FauxProperty,
              variables: { id: this.selectedProperty.id },
            })
          );

          data.fauxProperty.buildings.find((b) => b.id === addFauxUnitToFauxBuilding.fauxBuildingId).units.push(addFauxUnitToFauxBuilding);

          if (this.customSortOrder) {
            const itemIndex = this.customSortOrder.indexOf(item.id);
            this.customSortOrder.splice(itemIndex + 1, 0, addFauxUnitToFauxBuilding.id);
          }

          store.writeQuery({
            query: FauxProperty,
            variables: { id: this.selectedProperty.id },
            data,
          });

          this.updateFauxUnitSector({
            id: addFauxUnitToFauxBuilding.id,
            sectorId: item.sectorId,
          });

          this.hightlightedUnit = addFauxUnitToFauxBuilding.id;
        },
      });

      this.$amplitude.track({
        event_type: "Duplicate row",
        event_properties: {
          type: item.sectorId,
          buildingNumber: item.buildingNumber,
          constructionYear: item.constructionYear,
          area: item.totalArea,
        },
      });
    },

    async createFauxUnit(tablePosition) {
      this.fauxPropertyDataEdited = true;
      this.selectedSort = this.sortByLastOrder;

      const buildingId = this.selectedSimulation.buildings[0].id;
      const communeCode = getPropertyAddress(this.property)?.municipalityCode || undefined;
      const usageCode = this.selectedSimulationUnits?.find((u) => u.usageCode)?.usageCode ?? 120; // Fritliggende en-families hus

      this.$apollo.mutate({
        mutation: AddFauxUnitToFauxBuilding,
        variables: {
          buildingId: buildingId,
          input: {
            communeCode: communeCode,
            usageCode: usageCode.toString(),
          },
        },
        update: (store, { data: { addFauxUnitToFauxBuilding } }) => {
          const data = structuredClone(
            store.readQuery({
              query: FauxProperty,
              variables: { id: this.selectedProperty.id },
            })
          );

          data.fauxProperty.buildings.find((b) => b.id === addFauxUnitToFauxBuilding.fauxBuildingId)?.units?.push(addFauxUnitToFauxBuilding);

          const unitId = addFauxUnitToFauxBuilding.id;

          if (this.customSortOrder) {
            if (tablePosition === "top") {
              this.customSortOrder.unshift(unitId);
            } else {
              this.customSortOrder.push(unitId);
            }
          }

          store.writeQuery({
            query: FauxProperty,
            variables: { id: this.selectedProperty.id },
            data,
          });

          this.hightlightedUnit = unitId;
        },
      });

      this.$amplitude.track({
        event_type: "Add row",
        event_properties: {
          route: tablePosition === "top" ? "Route 1" : "Route 2",
        },
      });
    },

    async updateFauxUnit(item) {
      this.fauxPropertyDataEdited = true;
      this.selectedSort = this.sortByLastOrder;
      this.hightlightedUnit = null;

      const buildingId = this.selectedSimulation.buildings.find((b) => b.buildingNumber == item.buildingNumber).id;

      await this.$apollo.mutate({
        mutation: UpdateFauxUnit,
        variables: {
          input: {
            id: item.id,
            name: item.name,
            totalArea: item.totalArea,
            fauxBuildingId: buildingId,
            communeCode: item.communeCode,
            usageCode: item.usageCode,
          },
        },
        update: (store, { data: { updateFauxUnit } }) => {
          const data = structuredClone(
            store.readQuery({
              query: FauxProperty,
              variables: { id: this.selectedProperty.id },
            })
          );

          const unit = structuredClone(data.fauxProperty.buildings.flatMap((b) => b?.units).find((u) => u.id === item.id));

          unit.name = updateFauxUnit.name;
          unit.totalArea = updateFauxUnit.totalArea;
          unit.fauxBuildingId = updateFauxUnit.fauxBuildingId;

          store.writeQuery({
            query: FauxProperty,
            variables: { id: this.selectedProperty.id },
            data,
          });
        },

        optimisticResponse: {
          __typename: "Mutation",
          updateFauxUnit: {
            __typename: "FauxUnit",
            id: item.id,
            name: item.name,
            totalArea: item.totalArea,
            fauxBuildingId: item.buildingId,
          },
        },
      });
    },

    async updateFauxUnitSector(item) {
      this.fauxPropertyDataEdited = true;
      this.selectedSort = this.sortByLastOrder;
      this.hightlightedUnit = null;

      this.$apollo.mutate({
        mutation: SetFauxUnitSector,
        variables: {
          input: {
            fauxUnitID: item.id,
            sectorEnum: item.sectorId,
          },
        },
      });
    },

    async updateFauxBuildingConstructionYear(item) {
      this.fauxPropertyDataEdited = true;
      this.selectedSort = this.sortByLastOrder;
      this.hightlightedUnit = null;

      let building = this.selectedSimulation.buildings.find((b) => b.id === item.buildingId);

      await this.$apollo.mutate({
        mutation: UpdateFauxBuilding,
        variables: {
          input: {
            id: building.id,
            buildingNumber: building.buildingNumber,
            constructionYear: item.constructionYear,
            buildingId: building.buildingId,
            latitude: building.latitude,
            longitude: building.longitude,
            name: building.name,
          },
        },
      });
    },

    async deleteFauxUnit(item) {
      if (confirm(`Remove ${item.name}?`)) {
        this.fauxPropertyDataEdited = true;
        this.selectedSort = this.sortByLastOrder;

        await this.$apollo.mutate({
          mutation: DeleteFauxUnit,
          variables: {
            input: item.id,
          },
          update: (store) => {
            const property = {
              ...store.readQuery({
                query: FauxProperty,
                variables: { id: this.selectedProperty.id },
              }),
            };

            const building = structuredClone(property.fauxProperty.buildings.find((b) => b.id === item.buildingId));

            building.units = building.units.filter((u) => u.id !== item.id);

            store.writeQuery({
              query: FauxProperty,
              variables: { id: this.selectedProperty.id },
              data: property,
            });

            this.customSortOrder = this.customSortOrder.filter((id) => id !== item.id);
          },

          optimisticResponse: {
            __typename: "Mutation",
            deleteFauxUnit: true,
          },
        });

        this.$amplitude.track({
          event_type: "Delete row",
          event_properties: {
            type: item.sectorId,
            buildingNumber: item.buildingNumber,
            constructionYear: item.constructionYear,
            area: item.totalArea,
          },
        });
      }
    },

    async setPreferredRentalCalculation(simulation, rentalLevel) {
      const rentalCalculationEnum = rentalLevel === "marketRent" ? "MARKET_RENT" : "CURRENT_RENT";

      this.$apollo.mutate({
        mutation: SetFauxPropertyRentCalculation,
        variables: {
          input: {
            fauxPropertyID: simulation.id,
            rentCalculationEnum: rentalCalculationEnum,
          },
        },

        update: (store, { data: { setFauxPropertyRentCalculation } }) => {
          const data = structuredClone(
            store.readQuery({
              query: FauxProperty,
              variables: { id: simulation.id },
            })
          );

          data.fauxProperty.rentCalculationEnum = setFauxPropertyRentCalculation.rentCalculationEnum;

          store.writeQuery({
            query: FauxProperty,
            variables: { id: this.selectedProperty.id },
            data,
          });
        },

        optimisticResponse: {
          __typename: "Mutation",
          setFauxPropertyRentCalculation: {
            __typename: "FauxProperty",
            id: simulation.id,
            rentCalculationEnum: rentalCalculationEnum,
          },
        },
      });
    },

    async download() {
      this.closeDownloadModal();
      this.exportLoading = true;

      const simulationsWithRentalLevels = await this.$apollo.query({
        query: PropertySimulationsRentalLevels,
        variables: {
          bfeNumber: this.property.bfeNumber,
          ...(this.searchFilters && { search: { sectorFilters: this.searchFilters } }),
        },
        errorPolicy: "ignore",
      });

      const units = this.data.map((unit) => {
        return {
          name: this.$options.filters.addressShort(unit.address),
          type: getTypeFromSector(this.getUnitSectorId(unit), this.$i18n),
          buildingNumber: unit.buildingNumber,
          constructionYear: unit.constructionYear,
          totalArea: unit.totalArea,
          bottomRent: this.getUnitRentalPriceMin(unit),
          avgRent: this.getUnitRentalPriceAvg(unit),
          topRent: this.getUnitRentalPriceMax(unit),
          totalRent: this.getUnitTotalRent(unit),
        };
      });

      const simualtions = simulationsWithRentalLevels.data.fauxPropertiesByBfeNumber.map((simulation) => {
        return {
          name: simulation.name,
          units: simulation.buildings.flatMap((b) => {
            return b.units.map((u) => {
              return {
                name: u.name,
                type: getTypeFromSector(u.sectorEnum, this.$i18n),
                buildingNumber: b.buildingNumber,
                constructionYear: b.constructionYear,
                totalArea: u.totalArea,
                bottomRent: this.getUnitRentalPriceMin(u),
                avgRent: this.getUnitRentalPriceAvg(u),
                topRent: this.getUnitRentalPriceMax(u),
                totalRent: this.getUnitTotalRent(u),
              };
            });
          }),
        };
      });

      const rentalSheet = createRentalLevelsSheet(units, "Rent levels");

      const simulationSheets = simualtions.map((simulation) => {
        return createRentalLevelsSheet(simulation.units, simulation.name, "");
      });

      writeSheetsToXSLXAndSave([rentalSheet, ...simulationSheets], "rental-levels");

      this.$amplitude.track({ event_type: "Download alle" });

      setTimeout(() => {
        this.exportLoading = false;
      }, 2000);
    },

    async bulkEditSave(event) {
      const fauxPropertyId = this.selectedProperty.id;
      const oldSectorEnumValue = event.bulkEditModalSectorOldValue;
      const newSectorEnumValue = event.bulkEditModalSectorNewValue;

      await this.$apollo.mutate({
        mutation: UpdateFauxPropertyUnitSectors,
        variables: {
          input: {
            fauxPropertyID: fauxPropertyId,
            newSectorEnum: newSectorEnumValue,
            oldSectorEnum: oldSectorEnumValue,
          },
        },
      });

      this.fauxPropertyDataEdited = true;
      this.bulkEditModalActive = false;
    },

    closeDownloadModal() {
      this.downloadModalActive = false;
      this.downloadConditionsConfirmed = false;
    },

    tooltipClicked(tooltip) {
      this.$amplitude.track({
        event_type: "Tooltip clicked",
        event_properties: {
          tooltip: tooltip,
        },
      });
    },

    requestContracts() {
      const propertyAddress = getPropertyAddress(this.property);
      const propertyCentroid = getPropertyCentroid(this.property);

      this.$apollo.mutate({
        mutation: RequestContracts,
        variables: {
          input: {
            userEmail: this.me.email,
            userName: this.me.name,
            userPhoneNumber: this.me.phoneNumber.toString(),
            latitude: propertyCentroid.lat.toString(),
            longitude: propertyCentroid.lng.toString(),
            address: getAddressAsLongString(propertyAddress),
            bfeNumber: this.property.bfeNumber.toString(),
          },
        },
      });

      this.$amplitude.getLeases();

      this.requestRentContractsModalActive = false;
    },

    toggleExpandUnitTable(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
      this.expandUnitTable = !this.expandUnitTable;
    },

    colorBySectorId: colorBySectorId,
    getTypeFromSector: getTypeFromSector,
  },
};
